body {
  height: 100%;
  display: flex;
  background: url('../../images/pages/login-bg.jpg') no-repeat center center;
  background-size: cover;

  /* @2x Images (Pixel Ratio of 1.25+) */
  @media only screen and (-o-min-device-pixel-ratio: 5/4),
  only screen and (-webkit-min-device-pixel-ratio: 1.25),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx) {
    background-image: url('../../images/pages/login-bg@2x.jpg');
  }

  /* @3x Images (Pixel Ratio of 2.25+) */
  @media only screen and (-o-min-device-pixel-ratio: 9/4),
  only screen and (-webkit-min-device-pixel-ratio: 2.25),
  only screen and (min-device-pixel-ratio: 2.25),
  only screen and (min-resolution: 2.25dppx) {
    background-image: url('../../images/pages/login-bg@3x.jpg');
  }
}

.container__login {
  flex-direction: column;
  justify-content: stretch;
}

.top-login-logo {
  width: 100%;
  max-width: 216px;
}

.fhr-block__login {
  justify-content: center;
  align-items: center;
}

.fhr-button__login {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}

.fhr-google-icon {
  width: 90px;
  padding: 11px 0;
}

.fhr-login-icon-wrapper {
  margin-bottom: 1.67rem;
}

.fhr-login-title {
  font-size: 2rem;
  color: #295d75;
  text-transform: uppercase;
  margin-top: 0;
}